body.ecommerce-product-catalog{
    #product_filters_bar{
      display:none;
    }

    #container.content-area.product-catalog .price-value.green-price,
    #product_page.boxed #product_details table tr td.price-value.green-price,
    .price-value.green-price,
    .product-price.green-price{
      color: $primary !important;
    }

    .green-box.button{
      background-color: $primary !important;
    }

    input, select, textarea{
      font-family: $font-family-base !important;
    }

    #content .boxed #product_details>div.al-box.success, .al-box.success{
      background-color: $primary !important;
    }
    #product_listing .al-box a, .fixed-box .al-box a, .product-entry .al-box a{
      color: #ffffff;
    }
    #after-product-details .ic_tabs>h3, #content #product_page.boxed .after-product-details .ic_tabs>h3, #product_page .ic_tabs>h3, #product_page.boxed div.entry-content .after-product-details .ic_tabs>h3, .ic_tabs>h3, body .ic_tabs>h3{
      border: none !important;
    }
    .add-to-shopping-cart button:before{
      top: 4px;
    }
    #product_details .al-box, .boxed #product_details .al-box{
      color: #ffffff;
      box-shadow: none;
      border-radius: 0px;
      padding: 12px 10px 12px 40px;
    }
}

div.ic-form input.medium, div.ic-form textarea.medium{
  background-color: rgba(245,245,245,1) !important;
}

html body input.to_cart_submit.button,
html body #shopping-cart-submit-container input[name=cart_submit]{
  background-color: $primary;
  border-radius: $border-radius;
  padding: 12px;
  font-weight: bold;
  font-size: 14*$px-base;
  font-family: $font-family-base;
  color: #ffffff;
}

