/* -----------------------------------------
   SUCHE & SUCHSEITE
----------------------------------------- */

#search {
  width: 250px;
  margin: auto;
  margin-right: 20px;

  #searchbar{
    background-color: $secondary;
    height: 39px;
  }
  form{
    input {
      border-radius: 0;
      background: transparent;
      border: none;
      color: $primary;
      font-size: 18*$px-base;
    }
    input:focus-visible {
      border: none !important;
      outline: none !important;
    }

    input::placeholder {
      color: $primary;
    }
  }
}

#searchbar{
height: 39px;
  form{
    height: 39px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
  }
  #searchtext{
    width: 190px;
    font-size: 24*$px-base;
    height: 30px;
    display: inline-blockblock;
    margin: 0px;
    padding: 0px;
  }
  #searchsubmit{
    background-image: url('images/icons/header/suche.svg');
    width: 30px;
    height: 30px;
    border-bottom: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
  }
}