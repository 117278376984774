/*
Theme Name: TourismusNetzwerk 3.0
Description: TourismusNetzwerk 3.0
Version: 3.0
Author: Shapefruit AG - Christian Lersch
Author URI: http://shapefruit.de
License: GPL
*/

/* AUTOREN */

/* !!!!!!!!!!!!!!!!!!!!!! RPT VERSION !!!!!!!!!!!!!!!!!!!!!!  */

/* -----------------------------------------
   GLOBALE STYLES
----------------------------------------- */

iframe{
  display: none;
}

.youtube-iframe-placeholder{
  background-color: $gold !important;
  .btn-primary{
    background-color: $tertiary !important;
    border: $tertiary !important;
    margin: 5px !important;
  }
}

html body{
  font-size: 15px;
  font-family: $font-family-base;
  overflow-x: hidden;
  &>.container-fluid{
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

h1, h2, h3, h4{
  color: $tertiary;
  font-weight: normal;
}

h1{
  font-size: 54*$px-base;
}

h2{
  font-size: 44*$px-base;
}

.hintergrundgrauverlauf{
  background: linear-gradient(0deg, rgba(255,255,255,0) 14%, rgba(223,225,227,1) 100%);
}

.btn-primary{
  background-color: $primary;
  color: #ffffff;
}

.fullpage, .singlepost{
  a{
    color: $primary;
    font-weight: bold;
  }
  a.btn{
    color: $secondary;
  }

  h1{
    font-size: 54*$px-base;
  }

  h2{
    font-size: 44*$px-base;
    color: $tertiary;
  }

  h3{
    font-size: 33*$px-base;
    font-weight: normal;
    color: $tertiary;
  }
  @media (min-width: 768px) {
    h1{
      font-size: 40*$px-base;
    }

    h2{
      //font-size: 30*$px-base;
    }

    h3{
      //font-size: 25*$px-base;
      margin-bottom: 15px;
    }
  }
  .blogcontentmain{
    @include media-breakpoint-up(lg) {
      padding-right: 55px;
    }
    .post{
      .wp-caption{
        margin: auto;
        max-width: 100%;
          img.size-large{
            width: 100%;
            height: auto;
          }
      }
    }
    .wp-caption.aligncenter{
      text-align: center;
    }

    h1, h1.posttitle{
      font-family: $headings-font-family !important;
      color: $tertiary !important;
      font-weight: normal !important;
      font-size: 54*$px-base !important;
      text-transform: uppercase;
      font-weight: 600 !important;
    }
  }
  .thumbnailboxwithquelle{

  }
  .postoptions{
    * {
      text-transform: uppercase !important;
      font-weight: normal !important;
    }
    .borderbox{
      border-top: solid 1px $quaternary;
      @include media-breakpoint-up(lg) {
        border-top: none;
        border-left: solid 1px $quaternary;
        padding-left: 20px;
      }
    }
    .shariffbereich{
      text-align: left;
      padding-top: 25px !important;
    }
  }
}

.avatarlogobox{
  margin-left: 30px;

  .avatar{
    img{
      border-radius: 500px;
      width: 185px;
      height: 185px;
    }
  }
  .rptlogo{
    margin-top: 50px;
  }
  .pic {
    display: flex;
    flex-direction: column;
    img{
      height: 100px;
      width: auto;
    }
  }
}

#wpadminbar {
  overflow: hidden;
}

/* -----------------------------------------
   POST STYLES
----------------------------------------- */

.headlinebox{
  text-align: center;
}

.headlinebox2{
  text-align: center;
  svg path{
    fill: $tertiary !important;
  }
}

.btn{
  font-weight: 400;
  border-radius: 4px;
  padding: 6px;
}

.btnmehr{
  background-color: $primary;
  border-radius: 15px;
  padding: 3px 8px;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  width: 75px;
  display: block;
  text-align: center;
}


.gimmemyarrow{
  margin-left: 5px;
  width: 17px;
  height: 14px;
  stroke: $secondary;
}

.fullpage{
  .gallery{
    img{
      width: 100%;
      height: auto;
      padding: 10px;
      border: none !important;
    }
  }
}

.titlebalken{
  height: auto;
  background-color: $quaternary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    color: $tertiary;
    font-weight: normal;
    font-size: 54*$px-base;
    @include media-breakpoint-down(xs) {
      font-size: 25*$px-base;
    }
  }
  &.slim{
    height: 120px;
  }
}

body.single .titlebalken{
  height: 270px;
}
body.blog, body.page, body.category{
  .titlebalken{
    height: 270px;
    @include media-breakpoint-down(xs) {
      height: 150px;
    }
  }
}

.weitereartikel{
  .crp_related{
    .crp_title{
      display: none;
    }
    .card .card-body{
      padding-top: 10px !important;
    }
  }
  .katbox{
    display: none;
  }
}

html body .ui-widget {
  font-family: $font-family-base !important;
}

.btn-info{
  background-color: $tertiary !important;
  border-color: $tertiary !important;;
}

blockquote{
  font-style: italic;
  margin-left: 35px;
}
