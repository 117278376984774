.bottomnav {
  @include media-breakpoint-up(md) {
    height: 155px;
  }
}

.footerlogos{
  margin-top: 35px;
}

.footer{
  background-color: $primary;
  height: 155px;

  *{
    color: $secondary;
  }

  #menu-footernav{
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    @include media-breakpoint-down(md) {
      justify-content: center;
      margin: 13px 0px 3px 0px;

    }

    a{

    }
    .seperator{
        margin: 0px 6px;
        display: inline-block;
    }
    li:last-of-type .seperator{
      display: none;

    }
  }

  .logo{
    align-items: center;
    svg{
      height: 115px;
      width: auto;
    }
  }
}