.page-template-weiterbildung{
  .type-tribe_events {
    .gueltigbis{
      text-decoration: underline;
    }
    a:hover{
      text-decoration: none;
      .article{
        background-color: $quaternary;
        h3{
          color: $tertiary
        }
      }
    }
    .article{
      background-color: $quaternary_light;
      padding: 20px;
      min-height: 260px;
    }


    h3{
      font-size: 22px;
    }
    .read-more a{
      font-weight: bold;
    }
    dt{
      text-align: left;
    }
  }

  .dynamic-facets .author{
    display: none;
  }
}

#tribe-events-content.elearning{
  .tribe-events-schedule{
    display: none;
  }
}