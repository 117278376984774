
input, textarea{
  border: solid 1px $primary;
  width: 270px;
}

textarea{
  width: 330px;
}

.comment-form-comment{
  label{
    display: block;
  }
}

.gglcptch{
  display: flex;
  justify-content: center;
}

.commentbox{
  display: none;
}

#commentboxopenbutton{
  color: $primary;
}

#commentboxclosebutton{
  display: none;
  color: $primary;
}

.kommentarbereich{
  text-align: center;

  .kommentarboxbutton{
    display: inline-blockblock;
    font-size: 14px;
    text-align: center;
    width: 150px;
    color: #ffffff !important;
    padding: 10px 25px;
    border-radius: 0px;
    background-color: $primary !important;
    text-decoration: none;
    font-weight: bold;
    &:hover{
      background-color: $primaryhover !important;
    }
  }
  .comment-list{
    text-align: left;
    padding: 20px 0;

    li{
      padding: 20px 0;
      border-bottom: solid 1px $primary;
    }
  }
}

.shariffbereich{
  text-align: center;
  @include media-breakpoint-down(xs) {
    .shariff.shariff-align-center ul {
      justify-content: left;
    }
  }
  .shariff-button.whatsapp svg{
    padding-left: 3px !important;
  }
  .shariff-button.info{
    a{
      border: none !important;
    }
  }
}

body.post-template-default .shariff ul{
  @include media-breakpoint-up(md) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

body.page-template .shariffbereich,
body.page-template-default .shariffbereich,
body.page-template-default .shariff.shariff-align-center ul {
  text-align: center;
  justify-content: center;
}